export const displayCurrency = (amount: number) =>
    amount.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });

// see https://gist.github.com/codeguy/6684588
export const slugify = (text: string) =>
    text
        .toString() // Cast to string (optional)
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/-$/g, ''); // Remove trailing - if any

/**
 * @see https://stackoverflow.com/a/1026087
 */
export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
