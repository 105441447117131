import React from 'react';
import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import MUIDialogTitle from '@mui/material/DialogTitle';
import MUIDialogContent from '@mui/material/DialogContent';
import { IconButton, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@emotion/react';

export type DialogProps = Pick<
    MUIDialogProps,
    'sx' | 'fullWidth' | 'open' | 'maxWidth' | 'TransitionProps' | 'fullScreen'
> & {
    /**
     * id requis pour l'accessibilité.
     * Assigné au titre de la dialog
     */
    id: string;
    children: React.ReactNode;
    mainTitle: string;
    disableEnforceFocus?: boolean;
    dialogComponent?: React.ComponentType<MUIDialogProps>;
    onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
    /**
     * des props supplémentaire à passer si on a définit dialogComponent.
     * todo : typer strictement les props en fonction de dialogComponent :
     * https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/
     */
    customProps?: any | undefined;
    sxDialogContent?: SxProps<Theme>;
};

function Dialog({
    children,
    onClose,
    open,
    maxWidth = 'xs',
    fullWidth = true,
    fullScreen = false,
    mainTitle,
    id,
    sx,
    dialogComponent,
    disableEnforceFocus,
    TransitionProps,
    customProps,
    sxDialogContent,
}: DialogProps) {
    const DialogComponent: React.ComponentType<
        MUIDialogProps & { customProps?: any | undefined }
    > = dialogComponent || MUIDialog;

    return (
        <DialogComponent
            onClose={onClose}
            aria-labelledby={id}
            open={open}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            sx={sx}
            disableEnforceFocus={disableEnforceFocus}
            TransitionProps={TransitionProps}
            {...(customProps && { customProps })}
        >
            <MUIDialogTitle
                id={id}
                sx={{
                    backgroundColor: 'primary.dark',
                    color: 'primary.contrastText',
                }}
            >
                {mainTitle}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.common.white,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MUIDialogTitle>
            <MUIDialogContent sx={sxDialogContent}>{children}</MUIDialogContent>
        </DialogComponent>
    );
}

export default Dialog;
