import React, { useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import { ApolloError } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import Dropzone from '@acdc/shared/src/ui/Dropzone';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import {
    acceptImage,
    megaBytesToBytes,
} from '@acdc/shared/src/utils/file-helpers';
import useInitialValues from './useInitialValues';
import useSubmit from './useSubmit';
import getChapterFormSchema from './getChapterFormSchema';
import DeleteChapterButton from '../DeleteChapterButton';

const imageMaxSize = megaBytesToBytes(5);

export interface ChapterFormValue {
    title: string;
    subTitle: string;
    chapterImage: File[];
}

export interface ChapterFormProps {
    value?: DeepPartial<Chapter> | undefined;
    onSuccess?: ((res: ChapterResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

/**
 * Formulaire de Chapter.
 * Si la valeur fournie a un id le Chapter est chargé depuis l'api avant de rendre le formulaire.
 */
function ChapterForm({ value, onSuccess, onError }: ChapterFormProps) {
    const { initialValues, initialChapterImages, chapter } =
        useInitialValues(value);
    const submit = useSubmit(value, onSuccess, onError);
    const chapterFormSchema = useMemo(
        () => getChapterFormSchema(value),
        [value]
    );

    if (!initialValues) {
        return <FormSkeleton nbInputs={2} />;
    }

    return (
        <Formik
            validationSchema={chapterFormSchema}
            initialValues={initialValues}
            onSubmit={(values: ChapterFormValue, formikHelpers) => {
                submit(values, formikHelpers);
            }}
        >
            {({ values, handleChange }: FormikProps<ChapterFormValue>) => (
                <Form data-testid="chapterForm">
                    <Stack direction="column" spacing={2}>
                        <Field
                            component={TextField}
                            inputProps={{ 'data-testid': 'title' }}
                            id="title"
                            label="Titre"
                            name="title"
                            required
                        />
                        <Field
                            component={TextField}
                            inputProps={{ 'data-testid': 'subTitle' }}
                            id="subTitle"
                            label="Sous titre"
                            name="subTitle"
                        />
                        <Box mt={2}>
                            <Dropzone
                                id="chapterImage"
                                name="chapterImage"
                                value={values.chapterImage}
                                onChange={handleChange}
                                label="Déposez votre image *"
                                multiple={false}
                                accept={acceptImage}
                                inputProps={{
                                    'data-testid': 'chapterImage',
                                }}
                                rejectionsListProps={{
                                    'data-testid': 'chapterImageRejections',
                                }}
                                validFilesListProps={{
                                    'data-testid': 'chapterImageValidFiles',
                                }}
                                initialApiFilesListProps={{
                                    'data-testid':
                                        'chapterImageInitialApiFiles',
                                }}
                                initialApiFiles={initialChapterImages}
                                maxSize={imageMaxSize}
                            />
                            <ErrorMessage
                                name="chapterImage"
                                component={ErrorHelperText}
                            />
                        </Box>
                        <SubmitButton
                            buttonProps={{
                                'data-testid': 'chapterFormSubmitButton',
                                sx: { width: '100%' },
                            }}
                        >
                            Suivant
                        </SubmitButton>
                        {chapter && (
                            <DeleteChapterButton
                                chapter={chapter}
                                variant="contained"
                            >
                                Supprimer
                            </DeleteChapterButton>
                        )}
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}

export default ChapterForm;
