import React from 'react';
import {
    Autocomplete as FormikAutocomplete,
    AutocompleteProps as FormikAutocompleteProps,
} from 'formik-mui';
import { CircularProgress, TextField, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';

export type AutocompleteProps = FormikAutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
> & {
    label: string;
    required?: boolean;
};

/**
 * Autocomplete de formik-mui qui s'affiche avec un TextField.
 */
function Autocomplete({ field, ...props }: AutocompleteProps) {
    const { touched, errors } = useFormikContext<any>();
    const theme = useTheme();

    return (
        <FormikAutocomplete
            {...props}
            field={field}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={field.name}
                    error={touched[field.name] && !!errors[field.name]}
                    helperText={errors[field.name] as string}
                    label={props.label}
                    InputProps={{
                        ...theme.components?.MuiTextField?.defaultProps
                            ?.InputProps,
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {params.InputProps.startAdornment}
                                {props.loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                            </>
                        ),
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                    required={props?.required}
                />
            )}
        />
    );
}

export default Autocomplete;
