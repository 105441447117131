import {
    ApolloClient,
    InMemoryCache,
    from,
    ApolloLink,
    NormalizedCacheObject,
    ApolloCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import LocalStorageKeys from './LocalStorageKeys';
import createAuthorizationLink from './createAuthorizationLink';
import createTokenRefreshLink, {
    CreateTokenRefreshLinkConfigs,
} from './createTokenRefreshLink';
import { history } from '../router';
import GetUserResponse from '../features/user/GetUserResponse';

function baseInitApollo<RefreshTokenPayload = any>(
    apiEntrypoint: string,
    localStorageKeys?: LocalStorageKeys,
    refreshTokenConfigs?: CreateTokenRefreshLinkConfigs<RefreshTokenPayload>,
    cache?: ApolloCache<NormalizedCacheObject> | undefined
) {
    const links: ApolloLink[] = [];

    if (refreshTokenConfigs && localStorageKeys) {
        links.push(
            createTokenRefreshLink<RefreshTokenPayload>(
                `${apiEntrypoint}/graphql`,
                localStorageKeys,
                refreshTokenConfigs
            )
        );
    }

    if (localStorageKeys?.authToken) {
        links.push(createAuthorizationLink(localStorageKeys.authToken));
    }

    links.push(
        createUploadLink({
            uri: `${apiEntrypoint}/graphql`,
        })
    );

    return new ApolloClient({
        cache: cache || new InMemoryCache(),
        link: from(links),
    });
}

function initApollo(
    apiEntrypoint: string,
    localStorageKeys?: LocalStorageKeys,
    cache?: ApolloCache<NormalizedCacheObject> | undefined
) {
    return baseInitApollo<GetUserResponse>(
        apiEntrypoint,
        localStorageKeys,
        {
            refreshTokenGraphqlQuery: `
            mutation RefreshToken($refreshToken: String!) {
                refreshTokenUser(input: {refreshToken: $refreshToken}) {
                    user {
                        token
                        roles
                    }
                }
            }
        `,
            jwtFieldInResponse: 'refreshTokenUser',
            handleRefreshTokenError: () => history.push('/deconnexion'),
            extractToken: (payload) => payload.user?.token!,
            extractRoles: (payload) => payload.user?.roles!,
        },
        cache
    );
}

export default initApollo;
