import React from 'react';
import { FieldProps } from 'formik';
import { fieldToSwitch } from 'formik-mui';
import UiBigSwitch, {
    BigSwitchProps as UiBigSwitchProps,
} from '../ui/BigSwitch';

// Imilaire à https://github.com/stackworx/formik-mui/blob/formik-mui%404.0.0-alpha.3/packages/formik-mui/src/Switch.tsx
export interface BigSwitchProps
    extends FieldProps,
        Omit<
            UiBigSwitchProps,
            | 'checked'
            | 'name'
            | 'value'
            | 'defaultChecked'
            | 'form'
            // Excluded for conflict with Field type
            | 'type'
        > {
    type?: string;
}

/**
 * ui/BigSwitch pour Formik.
 * Il faut ajouter la prop type="checkbox" quand on l'utilise avec Field :
 * <Field component={BigSwitch} type="checkbox" ...
 */
function BigSwitch({ checkedLabel, uncheckedLabel, ...props }: BigSwitchProps) {
    return (
        <UiBigSwitch
            {...fieldToSwitch(props)}
            checkedLabel={checkedLabel}
            uncheckedLabel={uncheckedLabel}
        />
    );
}

export default BigSwitch;
