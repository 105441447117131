import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import Yup from '@acdc/shared/src/yup/yupFr';

export const requiredIfCreate =
    (value: DeepPartial<Chapter> | undefined) =>
    (images: any[] | undefined) => {
        return Boolean(value?.id) || images === undefined || images.length > 0;
    };

const getChapterFormSchema = (value: DeepPartial<Chapter> | undefined) =>
    Yup.object().shape({
        title: Yup.string().label('Le titre').required(),
        subTitle: Yup.string().label('Le sous titre').optional(),
        chapterImage: Yup.array()
            .label("L'image")
            .test(
                'requiredIfCreate',
                "L'image est requise",
                requiredIfCreate(value)
            ),
    });

export default getChapterFormSchema;
